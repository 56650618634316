.my-jobs {

    margin-top: 20px;

    .my-job .my-job-box {
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 13px;
        padding: 24px 30px 0 30px;
        margin-top: 20px !important;
        max-width: 100%;
        word-break: break-word;
    }

    .job-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #233966;
    }

    .job-status-active {
        color: #1FAD83;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #D2EFE6;
    }

    .job-status-inactive {
        color: #C80000;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #F5CCCC;
    }


    .job-description {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: #323232;
    }

    .job-sub-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #233966;
    }

    .work-from {
        background: #E0F0FF;
        border-radius: 3px;
        padding: 2px 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: #233966;
    }

    .from-where-work {
        position: absolute;
        bottom: 0px;
        width: calc(100% - 60px);
        background-color: white;
        padding: 15px 0;
    }

    .no-word-brk {
        word-break: normal;
    }

    .custom-box {
        min-width: 100px;
        padding: 5px 10px;
        border: 1px solid #ced4da !important;
        border-radius: 5px;
    }

    .my-job .about-job {
        margin-bottom: 100px;
    }

    .small {
        max-height: 280px;
        overflow: hidden;
        transition: 0.5s;
    }

    .full {
        min-height: 175px;
        max-height: 100%;
        transition: 0.5s;
        overflow: hidden;
    }

    .full .icon-rotate {
        transform: rotate(180deg);
        transition: 1s;
    }
   
}


@media screen and (max-width:575px) {

    .my-job .my-job-box {
        padding: 24px 15px 0 15px;
    }

    .job-title {
        font-size: 17px !important;
        line-height: 21px !important;
    }

    .work-from {
        font-size: 15px;
        line-height: 20px;
    }

}