.guest-form-conatiner {
    width: 500px;
    padding-bottom: 20px;

    .css-tp0lum-MuiButtonBase-root-MuiButton-root svg {
        font-size: 1.2rem !important;
    }

    .close-btn {
        display: flex;
        justify-content: flex-end;
    }

    .Heading {
        display: flex;
        justify-content: center;
        padding: 25px;
    }

    .form-content {
        padding: 25px;
    }

}

.css-7r8d6e-MuiDialogActions-root {
    justify-content: center !important;
}

.css-15dgj6o {
    justify-content: center !important;
}